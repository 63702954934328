import styled from '@emotion/styled'

export const OtpBoxSet = styled.div`
  input:not(:first-child) {
    border-left: none;
  }
  display: flex;
`

export const OtpBox = styled.input`
  width: 10vw; /* Use relative width for responsiveness */
  max-width: 66px; /* Ensure it doesn’t exceed a certain size */
  height: 10vw;
  max-height: 81px;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  font-color: #343a40;
  font-family: Barlow, sans-serif;
  border: 1px solid #b5bbc1;
  outline: none;
  transition: border-color 0.3s;
  box-sizing: content-box;

  &:first-child {
    border-left: 1px solid #b5bbc1; /* Add left border for the first box */
  }

  &:last-child {
    border: 1px solid #b5bbc1; /* Add right border for the last box */
  }

  &:focus {
    border-color: #4caf50;
    box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
  }

  @media (max-width: 600px) {
    width: 12vw;
    height: 14vw;
    font-size: 1.2rem;
  }

  @media (max-width: 400px) {
    width: 12vw;
    height: 16vw;
    font-size: 1rem;
  }
`

export const Dash = styled.span`
  font-size: 24px;
  margin: 0 8px;
  color: #555;
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    margin: 0 6px;
    font-size: 1.2rem;
  }

  @media (max-width: 400px) {
    margin: 0 4px;
    font-size: 1rem;
  }
`
