import React, {
  ChangeEvent,
  ClipboardEvent,
  FC,
  FormEvent,
  KeyboardEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Alert, Link, Stack, SubmitButton, Typography, Checkbox } from '@papercutsoftware/pcds-react'
import axios from 'axios'
import { initializeEmailAddressOwnershipVerification, verifyEmailAddressOwnership } from '@/api/emailOwnership'
import { StyledFormWrapper } from '@/styles/firebaseUi.styles'
import { Dash, OtpBox, OtpBoxSet } from '@/styles/otpinput.styles'
import { ProductContext } from '@/context/product'

interface Props {
  email: string
  onSuccess: () => void
}

type ErrorKey = 'MAX_GENERATIONS' | 'INVALID_INPUT' | 'UNKNOWN_ERROR' | 'MAX_ATTEMPTS'

const errorMessages: Record<ErrorKey, string> = {
  MAX_GENERATIONS: "You've requested a code too many times. Wait 3 minutes, then try again.",
  INVALID_INPUT: 'Invalid input. Please try again.',
  UNKNOWN_ERROR: 'Something went wrong. Try again or resend a new code.',
  MAX_ATTEMPTS: 'Too many incorrect attempts. Wait 3 minutes before you try again.',
}

const otpLength = 6

const EmailOwnershipVerification: FC<Props> = ({ email, onSuccess: onVerificationSuccess }) => {
  const [code, setCode] = useState<string>('')
  const [trustBrowser, setTrustBrowser] = useState<boolean>(true)
  const [isCodeResent, setIsCodeResent] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [error, setError] = useState<ErrorKey | undefined>(undefined)
  const product = useContext(ProductContext)

  useEffect(() => {
    initializeVerification()
  }, [])

  useEffect(() => {
    if (error) {
      setIsCodeResent(false)
    }
  }, [error])

  const initializeVerification = async (): Promise<boolean> => {
    try {
      await initializeEmailAddressOwnershipVerification(email, product.getId())

      return true
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 429) {
        setError('MAX_GENERATIONS')
      } else {
        setError('UNKNOWN_ERROR')
      }

      return false
    }
  }

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()
    setError(undefined)
    setIsSubmitting(true)

    try {
      await verifyEmailAddressOwnership(email, code, trustBrowser)
      onVerificationSuccess()
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status === 429) {
        setError('MAX_ATTEMPTS')
      } else {
        setError('UNKNOWN_ERROR')
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleResendCode = async () => {
    setIsCodeResent(false)
    setError(undefined)

    const success = await initializeVerification()
    if (success) {
      setIsCodeResent(true)
    }
  }

  return (
    <StyledFormWrapper direction="column" spacing={3}>
      <form onSubmit={handleSubmit} data-testid="email-ownership-verification-form">
        <Stack direction="column" spacing={2}>
          <Typography color="heading" variant="h2" component="h2">
            Verify your email address
          </Typography>
          <Stack direction="column" spacing={6}>
            <Typography variant="body1" component="p">
              It looks like you have access to multiple organizations. Find the code we&apos;ve sent to your email, and
              enter it below.
            </Typography>
            <OtpInput onValueChange={setCode} />
          </Stack>
          <Stack direction="column" spacing={0}>
            <Stack marginLeft={-1} marginBottom={0.25}>
              <Checkbox
                id="email-ownership-verification-form-trust-browser-checkbox"
                label="Trust this browser"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setTrustBrowser(event.currentTarget.checked)
                }}
                checked={trustBrowser}
                data-testid="email-ownership-verification-form-trust-browser-checkbox"
                margin={-1}
              />
            </Stack>
            <Stack marginBottom={0.25}>
              <Typography variant="body2" component="p">
                Next time you use this browser, you won&apos;t need to enter a code.
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="column" spacing={4}>
            <SubmitButton
              label="Submit"
              loading={isSubmitting}
              data-testid="email-ownership-verification-form-submit"
            />
            <Typography variant="body2" component="p">
              Didn&apos;t receive the email? Try checking your spam folder.{' '}
              <Link
                href="#"
                onClick={handleResendCode}
                target="_self"
                linkColor="primary"
                data-testid="resend-code-link"
              >
                Resend new code
              </Link>{' '}
            </Typography>
          </Stack>
          {error !== undefined && <Alert severity="error" content={errorMessages[error]} data-testid="error-alert" />}
          {isCodeResent && (
            <Alert
              severity="success"
              title="New code sent"
              content="Check your email inbox for your new code"
              data-testid="success-alert"
            />
          )}
        </Stack>
      </form>
    </StyledFormWrapper>
  )
}

interface OtpInputProps {
  onValueChange: (otp: string) => void
}

const OtpInput = ({ onValueChange }: OtpInputProps) => {
  const [otp, setOtp] = useState<string[]>(new Array(otpLength).fill(''))
  const inputRefs = useRef<(HTMLInputElement | null)[]>([])

  const handleChange = (element: HTMLInputElement, index: number) => {
    const value = element.value.slice(-1) // Ensure only one character is entered
    if (!/^\d?$/.test(value)) return // Allow only digits
    const newOtp = [...otp]
    newOtp[index] = value
    setOtp(newOtp)
    onValueChange(newOtp.join(''))

    // Move to the next input
    if (value && index < 5) {
      inputRefs.current[index + 1]?.focus()
    }
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>, index: number) => {
    if (event.key === 'Backspace' && otp[index] === '') {
      if (index > 0) {
        inputRefs.current[index - 1]?.focus()
      }
    }
  }

  const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault() // Prevent the default paste behavior

    const pasteData = event.clipboardData
      .getData('text') // Get the pasted data as a string
      .replace(/\s+/g, '') // Remove all whitespace
      .replace(/[^0-9]/g, '') // Remove all non-numeric characters
      .slice(0, otpLength) // Limit to 6 characters

    const newOtp = pasteData.split('')
    setOtp(newOtp.concat(new Array(otpLength - newOtp.length).fill(''))) // Fill empty slots
    onValueChange(newOtp.join(''))

    // Populate the input boxes with the sanitized values
    newOtp.forEach((digit, idx) => {
      if (inputRefs.current[idx]) {
        inputRefs.current[idx]!.value = digit
      }
    })

    // Focus on the last relevant input box
    const lastFilledIndex = newOtp.length - 1
    if (inputRefs.current[lastFilledIndex]) {
      inputRefs.current[lastFilledIndex]!.focus()
    }
  }

  const handleFocus = (index: number) => {
    // Focus on the input that is clicked, ensuring it's in front of the number
    setTimeout(() => {
      inputRefs.current[index]?.setSelectionRange(1, 1)
    }, 0)
  }

  const otpBox = (index: number, value: string) => {
    return (
      <React.Fragment key={index}>
        <OtpBox
          data-testid={`otp-${index}`}
          ref={(el) => {
            inputRefs.current[index] = el
          }}
          type="text"
          maxLength={1}
          value={value}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target, index)}
          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => handleKeyDown(e, index)}
          onPaste={handlePaste}
          onClick={() => handleFocus(index)} // Ensures selection is in front
        />
      </React.Fragment>
    )
  }

  const otpHalfIndex = otp.length / 2

  return (
    <Stack direction="row">
      <OtpBoxSet>{otp.slice(0, otpHalfIndex).map((_, index) => otpBox(index, otp[index]))}</OtpBoxSet>
      <Dash>-</Dash>
      <OtpBoxSet>
        {otp.slice(otpHalfIndex).map((_, index) => otpBox(index + otpHalfIndex, otp[index + otpHalfIndex]))}
      </OtpBoxSet>
    </Stack>
  )
}

export default EmailOwnershipVerification
