import { FC, useState } from 'react'
import {
  Tip,
  Link,
  Stack,
  Typography,
  SearchableSelect,
  Button,
  OptionSearchableSelectType,
} from '@papercutsoftware/pcds-react'
import { useRouter } from 'next/router'
import { Tenant, UserType } from '@/api/tenants'
import { StyledFormWrapper } from '@/styles/firebaseUi.styles'

interface Props {
  tenants: Tenant[]
  userEmailAddress: string
  onChangeEmailAddressClicked: () => void
}

const TenantSelector: FC<Props> = ({ tenants, userEmailAddress, onChangeEmailAddressClicked }) => {
  const [isNoTenantSelectedError, setIsNoTenantSelectedError] = useState<boolean>(false)
  const [selectedTenant, setSelectedTenant] = useState<Tenant | undefined>(undefined)

  const router = useRouter()

  const sortedTenants = [...tenants]?.sort((a: Tenant, b: Tenant) => {
    return a.displayName?.localeCompare(b.displayName) || 0
  })

  const selectOptions = sortedTenants.map(
    (tenant): OptionSearchableSelectType => ({
      id: tenant.id,
      label: tenant.displayName,
    }),
  )

  const getTenant = (tenantId: string) => {
    return sortedTenants.find((tenant) => tenantId === tenant.id)
  }

  const handleSubmit = () => {
    if (selectedTenant === undefined) {
      setIsNoTenantSelectedError(true)

      return
    }

    const query = {
      ...router.query,
      tenantId: selectedTenant.id,
      prefilledEmail: userEmailAddress,
      ...(selectedTenant.userType === UserType.PreSignInUser && { signup: '' }),
    }
    router.push({ query })
  }

  return (
    <StyledFormWrapper direction="column" spacing={3}>
      <Stack direction="column" spacing={6}>
        <Stack direction="column" spacing={2}>
          <Typography color="heading" variant="h2" component="h2">
            Select your organization
          </Typography>
          <Typography color="default" variant="label" component="p">
            Organizations for <strong>{userEmailAddress}</strong>
            <br />
            <Link href="#" onClick={onChangeEmailAddressClicked} linkColor="primary" target="_self">
              Change email address
            </Link>
          </Typography>
        </Stack>

        <SearchableSelect
          errorMessage="Select organization to continue."
          error={isNoTenantSelectedError}
          label="Log in to:"
          placeholder="Search for or select an organization"
          id="select-tenant"
          onSelect={(option) => {
            if (option != null) {
              setSelectedTenant(getTenant(option.id))
            }
            setIsNoTenantSelectedError(false)
          }}
          options={selectOptions}
          defaultValue={null}
        />
      </Stack>

      <Stack direction="column" spacing={6}>
        <Button label="Continue" size="medium" onClick={handleSubmit} />
        <Tip severity="info" content={<Typography>For faster log in, bookmark each dashboard.</Typography>} />
      </Stack>
    </StyledFormWrapper>
  )
}
export default TenantSelector
